let _sessionStorage;
let _localStorage;

const storageAPIStub = {
  setItem: () => {},
  getItem: () => {},
  removeItem: () => {},
  clear: () => {},
};

try {
  // in several browser contexts attempting to access the getter for
  // sessionStorage throws an error. So this check allows us to verify
  // access and api is available
  window.sessionStorage.removeItem('__not-there__');
  _sessionStorage = window.sessionStorage;
} catch (e) {
  console.warn('sessionStorage not available');
  _sessionStorage = storageAPIStub;
}

try {
  // in several browser contexts attempting to access the getter for
  // localStorage throws an error. So this check allows us to verify
  // access and api is available
  window.localStorage.removeItem('__not-there__');
  _localStorage = window.localStorage;
} catch (e) {
  console.warn('localStorage not available');
  _localStorage = storageAPIStub;
}

export const sessionStorage = _sessionStorage;
export const localStorage = _localStorage;
