export const TIER_LOCAL = 'local';
export const TIER_TESTING = 'testing';
export const TIER_PREVIEW = 'preview';
export const TIER_MT = 'multi-tenant';
export const TIER_ST = 'single-tenant';
export const TIERS = {
  [TIER_LOCAL]: TIER_LOCAL,
  [TIER_TESTING]: TIER_TESTING,
  [TIER_PREVIEW]: TIER_PREVIEW,
  [TIER_MT]: TIER_MT,
  [TIER_ST]: TIER_ST,
};

// Radar uses a different naming convention for the tiers:
export const TIER_GLOBAL_TESTING = 'Testing';
export const TIER_GLOBAL_PRODUCTION = 'Production';

export const NON_PRODUCTION_TIERS = [TIER_LOCAL, TIER_TESTING, TIER_PREVIEW];

export const TIERS_ON_TESTING_WORKER = [TIER_TESTING];
export const TIERS_ON_PRODUCTION_WORKER = [TIER_PREVIEW, TIER_MT, TIER_ST];

export const SYNCED_WORKER_PUBLISH_TIME = 'SYNCED_WORKER_PUBLISH_TIME_START SYNCED_WORKER_PUBLISH_TIME_END';
export const SYNCED_WORKER_TIER_COLLECTIONS = {
  [TIER_TESTING]: 'TESTING_TIER_MANIFEST_COLLECTION_START TESTING_TIER_MANIFEST_COLLECTION_END',
  [TIER_PREVIEW]: 'PREVIEW_TIER_MANIFEST_COLLECTION_START PREVIEW_TIER_MANIFEST_COLLECTION_END',
  [TIER_MT]: 'MT_TIER_MANIFEST_COLLECTION_START MT_TIER_MANIFEST_COLLECTION_END',
  [TIER_ST]: 'ST_TIER_MANIFEST_COLLECTION_START ST_TIER_MANIFEST_COLLECTION_END',
};

export const WORKER_SYNC_MANIFEST_COLLECTION_LOADING_STRATEGIES = {
  GSP: 'gsp',
  LIVE_LOCATION: 'live-location',
};

export const LOG_TYPE_LOG = 'LOG';
export const LOG_TYPE_INFO = 'INFO';
export const LOG_TYPE_WARNING = 'WARNING';
export const LOG_TYPE_ERROR = 'ERROR';

export const LOG_LEVEL_VERBOSE = 'verbose-logging';
export const LOG_LEVEL_INFO = 'error-warning-and-info-logging';
export const LOG_LEVEL_WARN = 'error-and-warning-logging';
export const LOG_LEVEL_ERROR = 'error-only-logging';

export const LOG_TYPE_TO_LEVEL_MAP = {
  [LOG_LEVEL_VERBOSE]: [LOG_TYPE_LOG, LOG_TYPE_INFO, LOG_TYPE_WARNING, LOG_TYPE_ERROR],
  [LOG_LEVEL_INFO]: [LOG_TYPE_INFO, LOG_TYPE_WARNING, LOG_TYPE_ERROR],
  [LOG_LEVEL_WARN]: [LOG_TYPE_WARNING, LOG_TYPE_ERROR],
  [LOG_LEVEL_ERROR]: [LOG_TYPE_ERROR],
};

const defaultConfig = {
  publishLogsToStdOut: false,
  logLevel: LOG_LEVEL_ERROR,
};

export const UI_GATEWAY_CONFIGS = {
  [TIER_LOCAL]: {
    ...defaultConfig,
    publishLogsToStdOut: true,
    logLevel: LOG_LEVEL_VERBOSE,
  },
  [TIER_TESTING]: {
    ...defaultConfig,
    logLevel: LOG_LEVEL_VERBOSE,
  },
  [TIER_PREVIEW]: {
    ...defaultConfig,
  },
  [TIER_MT]: {
    ...defaultConfig,
  },
  [TIER_ST]: {
    ...defaultConfig,
  },
};

// going to s3 directly for speed and to avoid worker recursive loops
export const GSP_SERVICE_BASE_URL = 'https://s3.amazonaws.com/invision-global-static/production';
export const GSP_CLIENT_BASE_URL = 'https://static.invisionapp-cdn.com';
export const GSP_SERVICE_BASE_URL_LOCAL_PREVIEW = 'https://s3.amazonaws.com/invision-global-static-local/local-preview';
export const GSP_CLIENT_BASE_URL_LOCAL_PREVIEW = 'https://static.invisionapp-cdn.com/local-preview';

// support a complete version namespace that allows us to build
// fresh storage lookups (or invalidate old ones) by incrementing this key
export const STORAGE_KEY_VERSION = 'v1';

export const NAVIGATION_TYPE_CONTROLLED = 'controlled';
export const NAVIGATION_TYPE_PASS_THROUGH = 'pass-through';
export const NAVIGATION_TYPE_PERMANENT_REDIRECT = 'permanent-redirect';
export const NAVIGATION_TYPE_TEMPORARY_REDIRECT = 'temporary-redirect';
export const NAVIGATION_TYPE_CLIENT_REDIRECT = 'client-redirect';

export const COMMON_MATCHER_BOT = 'bot';

export const RESPONSE_TYPE_OK = 'ok';
export const RESPONSE_TYPE_UNSUPPORTED_ROUTE = 'unsupported-route';
export const RESPONSE_TYPE_FATAL_ERROR = 'fatal-error';
export const RESPONSE_TYPE_PERMANENT_REDIRECT = 'permanent-redirect';
export const RESPONSE_TYPE_TEMPORARY_REDIRECT = 'temporary-redirect';

export const RESPONSE_STATUS_CODE_MAP = {
  [RESPONSE_TYPE_OK]: 200,
  [RESPONSE_TYPE_UNSUPPORTED_ROUTE]: 303,
  [RESPONSE_TYPE_TEMPORARY_REDIRECT]: 302,
  [RESPONSE_TYPE_PERMANENT_REDIRECT]: 301,
  [RESPONSE_TYPE_FATAL_ERROR]: 500,
};

export const REQUEST_HEADER_REQUEST_CONTEXT = 'X-UI-RequestContext';
export const RESPONSE_HEADER_RUNTIME_CONTEXT = 'X-UI-RuntimeContext';
export const RESPONSE_HEADER_DEV_FEATURES = 'X-UI-DevFeatures';

export const COMMAND_TYPES = {
  MOUNT: 'mount',
  UNMOUNT: 'unmount',
};

export const EVENT_PREFIXES = {
  BEFORE: 'before',
  AFTER: 'after',
};

// How many transitions are allowed before app shell forces
// the next navigation to be a full page navigation. The number
// is arbitrary and seemed like a good balance of useful for the UX
// and the purposes of triggering a memory reset.
export const MAX_APP_TRANSITION_DEPTH_BEFORE_FULL_NAV = 15;

// To keep some level of control over memory use across the user journey
// we put a cap on the # of document apps that a user can visit during that
// page lifetime. 3 is chosen right now because it's a very rare use-case but it
// would allow some valuable multi-doc scenarios without full loads; it's also
// the max num of different document shown in the project sidebar.
export const MAX_DOC_VISITS_PER_PAGE_LIFETIME = 3;

export const GENERIC_EVENTS = {
  USER_VENDOR_CONTEXT_UPDATED: 'user-vendor-context-updated',

  // This event is meant to signal that navigation was detected
  // and we are informing listeners before app-shell commits to
  // processing the navigation. Other than detection logic, no
  // other app-shell logic will run to stop or start other features
  // before this navigation happens.
  APP_NAVIGATION: 'app-navigation',
};

export const MANIFEST_COLLECTION_MEM_STORE_EXPIRY_TTL_SECONDS = 60;
export const MANIFEST_COLLECTION_CACHE_EXPIRY_TTL_SECONDS = 120;
export const MANIFEST_ITEM_CACHE_EXPIRY_TTL_SECONDS = 60;
export const MANIFEST_COLLECTION_KV_EXPIRY_TTL_SECONDS = 140;
export const MANIFEST_ITEM_KV_EXPIRY_TTL_SECONDS = 60;

// 10 minutes
export const MANIFEST_COLLECTION_EXPIRY_TTL = 600000;
// 3 minutes
export const MANIFEST_ITEM_EXPIRY_TTL = 180000;

export const MANIFEST_DATA_LOAD_STATE_OK = 'ok';
export const MANIFEST_DATA_LOAD_STATE_EXPIRED = 'expired';
export const MANIFEST_DATA_LOAD_STATE_EMPTY = 'empty';
export const MANIFEST_DATA_LOAD_STATE_ERROR = 'error';
export const MANIFEST_DATA_LOAD_STATE_INVALID = 'invalid';

export const STORAGE_NAMESPACE_MANIFEST_COLLECTION = 'manifest-collection';
export const STORAGE_NAMESPACE_MANIFEST = 'manifest';

export const DATA_STORAGE_SRC_SYNCED_WORKER = 'synced-worker';
export const DATA_STORAGE_SRC_MEMORY_STORE = 'mem-store';
export const DATA_STORAGE_SRC_CF_CACHE = 'cf-cache';
export const DATA_STORAGE_SRC_CF_KV = 'cf-kv';
export const DATA_STORAGE_SRC_GSP = 'gsp';
export const DATA_STORAGE_SRC_INDIVIDUAL = 'individual';
export const DATA_STORAGE_SRC_UNKNOWN = 'unknown';

export const FORCE_OVERRIDE_SHA_PARAM = 'overrideSha';
export const FORCE_FEATURE_VERSIONS_KEY = 'forceFeatureVersions';
export const FORCE_FEATURE_FOR_TIER_PARAM = 'forceFeatureForTier';
export const FORCE_FEATURE_OFF_FOR_TIER_PARAM = 'forceFeatureOffForTier';
export const FORCE_DISABLE_SYNCED_WORKER_COLLECTIONS_PARAM = 'disableSyncedWorkerCollections';
export const FORCE_DISABLE_MEMORY_STORE_PARAM = 'disableMemoryStore';
export const FORCE_DISABLE_CACHE_PARAM = 'disableCFCache';
export const FORCE_DISABLE_KV_PARAM = 'disableCFKV';
export const FORCE_ENABLE_ITEM_CACHE_PARAM = 'enableItemCFCache';
export const FORCE_ENABLE_ITEM_KV_PARAM = 'enableItemCFKV';
export const FORCE_DEVELOP_FEATURES_PARAM = 'featureDev';
export const FORCE_DEVELOP_FEATURES_SECURE_PARAM = 'featureDevSecure';
export const FORCE_DEVELOP_FEATURES_HOST_PARAM = 'featureDevHost';
export const FORCE_CLEAR_DEVELOP_FEATURES_PARAM = 'clearFeatureDev';
export const FORCE_STANDALONE_PARAM = 'forceStandalone';
export const FORCE_COMPOSITION_PARAM = 'forceComposition';
export const FORCE_OVERRIDE_CONFIGS_PARAM = 'forceOverrideConfigs';
export const FORCE_ALLOW_EXTERNAL_SCRIPTS_PARAM = 'forceAllowExternalScripts';
export const FORCE_RPR_VERSIONS_PARAM = 'forceRprVersions';
export const FORCE_PREVIEW_RPR_RELEASES = 'forcePreviewRprReleases';
export const FORCE_DISABLE_DEVELOPMENT_VARIANTS = 'disableDevelopmentVariants';

export const FORCE_UPDATE_STORAGE_PARAM = 'updateStorage';
export const OVERRIDE_KEY_PARAM = 'internalOverrideKey';

export const COOKIE_NAME_DEVELOP_FEATURES = 'ui-gateway-dev-config-features';
export const COOKIE_VALUE_DEVELOP_SECURE = 'secure-dev';
export const COOKIE_VALUE_DEVELOP_HOST = 'host-dev';

export const FORCE_VERSION_TIERS = [TIER_LOCAL, TIER_TESTING, TIER_PREVIEW];
export const FORCE_VERSION_HOSTNAMES = ['performance.invisionapp.com', 'mt-v7.invisionapp.com'];

// there are several actions that we don't want easily accessible
// requiring one of these keys allows us to put a heavy amount of friction
// for anyone external to be able to perform an action
export const INTERNAL_OVERRIDE_KEYS = [
  // used in Pingdom checks for background updates
  '5e270145-b403-4931-846d-40fdbe37b9a4',
  // used for manual ad-hoc updates
  '14c386ca-df5e-469a-860d-f1120fa2d350',
  // used for updates during deployments
  'a98e4c0b-be76-40a4-95af-68c3de915726',
];

export const CACHE_URL_KEY_PREFIX = 'https://ui-gateway-cache.invision.works/';

export const SERVER_TIMER_SERVER_PROCESS_TIME = 'uig-spt';
export const SERVER_TIMER_BUILD_LOAD_CTXT = 'uig-blc';
export const SERVER_TIMER_LOAD_MANIFEST_COLLECTION = 'uig-gmc';

export const DEVELOPMENT_MANIFEST_VERSION_KEY = 'local-manifest-version';
export const DEVELOPMENT_MANIFEST_LOCALHOST_PORT = 8811;

export const HTML_TEMPLATE_TYPE_CONTROLLED = 'controlled-template';
export const HTML_TEMPLATE_TYPE_CLIENT_REDIRECT = 'client-redirect-template';
export const HTML_TEMPLATE_TYPE_FEATURE_FRAME = 'feature-frame-template';

export const APP_SHELL_DEBUG_PARAM = 'app-shell-debug';
export const APP_SHELL_DEBUG_STORAGE_KEY = 'app-shell-debug';

export const APP_SHELL_BUNDLE_VERSION_LEGACY = 'legacy';
export const APP_SHELL_BUNDLE_VERSION_MODERN = 'modern';

export const UI_GATEWAY_ASSETS_ROOT = '/ui-gateway';
export const INITIAL_FRAME_SRC = `${UI_GATEWAY_ASSETS_ROOT}/feature-frame-v5.html`;

export const GLOBAL_SERVICE_WORKER_VERSION = 'v3';
export const GLOBAL_SERVICE_WORKER_TIERS = [TIER_TESTING, TIER_PREVIEW, TIER_MT, TIER_ST, TIER_GLOBAL_TESTING, TIER_GLOBAL_PRODUCTION];
export const GLOBAL_SERVICE_WORKER_PRECACHE_TIERS = [TIER_TESTING, TIER_PREVIEW, TIER_MT, TIER_ST];
export const GLOBAL_SERVICE_WORKER_PRECACHE_DELAY = 1200;
export const GLOBAL_SERVICE_WORKER_QUOTA_ERR_MSG_TYPE = 'QUOTA_ERROR';
export const GLOBAL_SERVICE_WORKER_APP_SHELL_READY_MSG_TYPE = 'APP_SHELL_READY';
export const GLOBAL_SERVICE_WORKER_SEND_OFFLINE_VISITS_MSG_TYPE = 'SEND_OFFLINE_VISITS';
export const DISABLE_GLOBAL_SERVICE_WORKER_PARAM = 'disableGlobalServiceWorker';
export const GLOBAL_SERVICE_WORKER_STATE_STORAGE_KEY = 'global-service-worker-state';
export const GLOBAL_SERVICE_WORKER_PRECACHE_PROXY_BASE_URL = 'https://static-p2.invisionapp-cdn.com';
export const GLOBAL_SERVICE_WORKER_DISABLE_DEV_LOGS = true; // Manually toggle on-off if needed while developing

export const PRELOAD_TIERS = [TIER_TESTING, TIER_PREVIEW, TIER_MT, TIER_ST];
export const PRELOAD_HOSTNAMES = [];
export const PRELOAD_EXPIRATION = 1000 * 30; // 30 seconds
export const PRELOAD_STATUS = {
  PENDING: 'pending',
  RUNNING: 'running',
  LOADED: 'loaded',
  CONSUMED: 'consumed',
  CANCELLED: 'cancelled',
};

export const APP_SHELL_DEV_SERVER = {
  HOSTNAME: 'localhost',
  PORT: 8888,
  APP_SHELL_FILE_PATH: '/app-shell.js',
  APP_SHELL_LEGACY_FILE_PATH: '/app-shell-legacy.js',
  POLYFILLS_FILE_PATH: '/polyfills.js',
};

// In order to support custom doc icons for Freehand, we let Freehand manage its own favicons.
export const FAVICON_PATHS = {
  DEFAULT: 'default',
  DOC_VIEWER: 'harmony',
  STUDIO_WEB: 'harmony',
  PROTOTYPE: 'prototype',
  SPEC: 'spec',
};

// is used to retrieve the UI variant from query string parameters
export const UI_VARIANT_KEY = 'uiVariant';
// is used to save and retrieve embed config from session storage
export const UI_VARIANT_CONFIG_KEY = 'uiVariantContext';

export const OAUTH_QUERY_PARAM_KEY = 'oauth';
export const OAUTH_MODE_HOSTED = 'hosted';
export const OAUTH_ACCESS_TOKEN_REQUESTED_MESSAGE_NAME = 'inv:oauth:accessToken:requested';
export const OAUTH_ACCESS_TOKEN_REQUESTED_MESSAGE_VERSION = '1';
export const OAUTH_ACCESS_TOKEN_GRANTED_MESSAGE_NAME = 'inv:oauth:accessToken:granted';
export const OAUTH_ACCESS_TOKEN_GRANTED_MESSAGE_VERSION = '1';

// used for reserved domain logic
export const LOGIN_KEY = 'login';

export const DOMAIN_REDIRECT_MAP = {
  ['login.invisionapp.com']: {
    redirectUrl: 'login.invisionapp.com/auth/sign-in',
    featureConfigKey: LOGIN_KEY,
  },
  ['login.invisionbeta.com']: {
    redirectUrl: 'login.invisionbeta.com/auth/sign-in',
    featureConfigKey: LOGIN_KEY,
  },
};
