// This script is responsible of registering the global service worker.
//
// It runs in two different contexts:
// - As a standalone script, published as a static asset in the global static pipeline
//   at https://static.invisionapp-cdn.com/cloud-ui/global-service-worker-loader.[hash].js
//   In this case, once the script has been loaded it will setup the global static worker
//   registration immediately.
// - Bundled within App Shell.
//   In this case, the global static worker registration will be invoked when needed.
//
// The following variables are injected as globals at build time:
// - __GLOBAL_SERVICE_WORKER_PATH__: points at a local asset in development and
//   at a GSP asset in prod.
// - __SHOULD_LOAD_SERVICE_WORKER_IMMEDIATELY__: true if this is the standalone
//   script build, or false if it's the App shell one.
//
import { Workbox } from 'workbox-window';
import {
  DISABLE_GLOBAL_SERVICE_WORKER_PARAM,
  GLOBAL_SERVICE_WORKER_TIERS,
  GLOBAL_SERVICE_WORKER_PRECACHE_TIERS,
  GLOBAL_SERVICE_WORKER_QUOTA_ERR_MSG_TYPE,
  GLOBAL_SERVICE_WORKER_STATE_STORAGE_KEY,
  GLOBAL_SERVICE_WORKER_SEND_OFFLINE_VISITS_MSG_TYPE,
} from 'common/constants';
import { localStorage } from 'app-shell/browser-storage';

// Service workers have a "scope" relative to the path at which they are loaded.
// Our scope has to be the root of the document - meaning we have to load it at "/".
// This is particularly challenging considering gsp assets are on a separate domain.
// We’ve already solved this problem by making GSP assets reachable from the root with a “masked” path.
// So, if we make the service worker a file published to "/spa/cloud-ui/global-service-worker.[hash].js"
// we can reference it via a relative path "/gsp~~spa~~cloud-ui~~global-service-worker.[hash].js"
// (the double tilde is used to allow a way to understand where the slashes should go when
// proxying that value from GSP.
const maskGlobalServiceWorkerPath = (path) => {
  return `/gsp${path.split('https://static.invisionapp-cdn.com').pop().replace(/\//g, '~~')}`;
};

export const setupGlobalServiceWorker = (options = {}) => {
  const {
    isDev,
    globalServiceWorkerPath = __GLOBAL_SERVICE_WORKER_PATH__,
    logger = console,
    rum = window.rum,
    forceUnregister = false,
    fromAppShell = false,
    getFeatureNameFromUrl = () => 'unknown',
  } = options;
  const tier = window.inGlobalContext?.appMetaData?.tier;

  if (!('serviceWorker' in navigator)) {
    return;
  }

  // Unregister the service worker if the DISABLE_GLOBAL_SERVICE_WORKER_PARAM query-param is set or if we force the
  // un-registration from the caller.
  // Please notice that the service worker will run and remain active until the next refresh.
  if (forceUnregister || location.search.includes(DISABLE_GLOBAL_SERVICE_WORKER_PARAM) || localStorage.getItem(GLOBAL_SERVICE_WORKER_STATE_STORAGE_KEY) === 'off') {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (let registration of registrations) {
        registration.unregister();
      }
    });
    if (location.search.includes(DISABLE_GLOBAL_SERVICE_WORKER_PARAM)) {
      // If we used the DISABLE_GLOBAL_SERVICE_WORKER_PARAM set a flag in the local storage to persist the change.
      localStorage.setItem(GLOBAL_SERVICE_WORKER_STATE_STORAGE_KEY, 'off');
    }
    return;
  }

  // This bit of code determines if the global service worker should be loaded or not.
  const shouldLoadGlobalServiceWorker = GLOBAL_SERVICE_WORKER_TIERS.includes(tier) || isDev;
  if (!shouldLoadGlobalServiceWorker) {
    return;
  }

  // Global Service Worker setup result
  const globalServiceWorker = {
    isLoaded: false,
    workbox: undefined,
    cacheUrls: () => {},
  };

  // The service worker instance will points at a local asset in development or
  // at a masked GSP asset in prod.
  const path = isDev ? globalServiceWorkerPath : maskGlobalServiceWorkerPath(globalServiceWorkerPath);
  const workbox = new Workbox(path, { scope: '/' });

  workbox.addEventListener('message', (event) => {
    switch (event.data.type) {
      // Track the service worker "exceeded quota" error.
      case GLOBAL_SERVICE_WORKER_QUOTA_ERR_MSG_TYPE: {
        rum?.markCustomEvent('GlobalServiceWorkerQuotaError');
        logger?.warn('The Global Service Worker cache has exceeded its quota.');
        break;
      }
      // Track the times a user navigated to an offline page.
      case GLOBAL_SERVICE_WORKER_SEND_OFFLINE_VISITS_MSG_TYPE: {
        if (event.data.payload?.length > 0) {
          event.data.payload.forEach(({ url, timestamp }) => {
            const offlineVisitFeatureName = getFeatureNameFromUrl(url);
            rum?.markCustomEvent('GlobalServiceWorkerOfflineVisit', { offlineVisitUrl: url, offlineVisitTimestamp: timestamp, offlineVisitFeatureName });
          });
          logger?.info(`The Global Service Worker has detected and reported ${event.data.payload.length} offline visits.`);
        }
        break;
      }
    }
  });

  // Register the global service worker on each app-shell load (basically on page refresh).
  workbox.register();

  // Send a post-message to the service worker to let it know that app-shell is now ready to handle its messages.
  if (fromAppShell) {
    workbox.messageSW({ type: 'APP_SHELL_READY' });
  }

  // Manually caches URLs
  const cacheUrls = (urlsToCache = []) => {
    const shouldPrecache = GLOBAL_SERVICE_WORKER_PRECACHE_TIERS.includes(tier) || isDev;
    if (shouldPrecache && urlsToCache.length > 0) {
      workbox.messageSW({
        type: 'CACHE_URLS', // Handled automatically by Workbox
        payload: { urlsToCache },
      });
    } else {
      logger?.info("Pre-caching won't run: it's not enabled in this environment or the pre-cache resources list is empty.");
    }
  };

  globalServiceWorker.isLoaded = true;
  globalServiceWorker.workbox = workbox;
  globalServiceWorker.cacheUrls = cacheUrls;

  return globalServiceWorker;
};

if (__SHOULD_LOAD_SERVICE_WORKER_IMMEDIATELY__) {
  setupGlobalServiceWorker();
}
